import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

// STYLE
const TeamPicturesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .section-intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    text-align: left;
    width: 100%;
    flex-wrap: wrap;

    h2 {
      max-width: 800px;
    }

    p {
      max-width: 560px;
    }

    @media only screen and (max-width: 1300px) {
      justify-content: center;
    }
  }

  .team {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;

    .team-member {
      display: flex;
      flex-direction: column;
      gap: 1em;
      max-width: 300px;

      .team-member-picture {
        border-radius: var(--border-radius);
        aspect-ratio: 1/1;
      }

      .team-member-info {
        display: flex;
        flex-direction: column;
        gap: 0.2em;

        .name {
          color: var(--primary-font-color);
          font-size: var(--medium-font-size);
        }

        .role {
          color: var(--secondary-font-color);
          font-size: var(--small-font-size);
        }
      }
    }
  }
`

const TeamPictures = ({ slice }) => {
  const data = slice.primary
  const items = slice.items

  return (
    <TeamPicturesSection className={`container`} id={data.section_id}>

      <div className="section-intro">
        <h2>{data.section_title.text}</h2>
        <p>{data.section_paragraph}</p>
      </div>

      <div className="team">
        {items.map((item, index) => (
          <div className="team-member" key={`team-member-${index}`}>
            <GatsbyImage className="team-member-picture" image={item.team_member_picture.localFile.childImageSharp.gatsbyImageData} alt={item.team_member_picture.alt} />
            <div className="team-member-info">
              <span className="name">{item.team_member_name}</span>
              <span className="role">{item.team_member_role}</span>
            </div>
          </div>
        ))}
      </div>
      
    </TeamPicturesSection>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTrombinoscope on PrismicHomepageDataBodyTrombinoscope {
    slice_type,
    primary {
      section_id
      section_title {
        text
      }
      section_paragraph
    }
    items {
      team_member_name
      team_member_role
      team_member_picture {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default TeamPictures