import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

// Icons
import { FiExternalLink } from "react-icons/fi/";
import { SiWikipedia } from "react-icons/si/";

// Components & Utils
import Link from "../utils/Link";

// STYLE
const WikipediaQuoteSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  div.content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    .quote-image {
      aspect-ratio: 1/1;
      max-width: 400px;
      border-radius: var(--border-radius);
    }

    .wikipedia-icon {
      --icon-size: 60px;
      width: var(--icon-size);
      height: var(--icon-size);
      color: var(--secondary-font-color);
      z-index: 2;
    }

    .quote {
      display: flex;
      flex-direction: column;
      gap: 0.8em;
      max-width: 780px;
      z-index: 2;

      p {
        color: var(--primary-font-color);
      }
    }
  }
`;

const WikipediaQuote = ({ slice }) => {
  const data = slice.primary;

  return (
    <WikipediaQuoteSection className={`container`} id={data.section_id}>
      <GatsbyImage
        className="quote-image"
        image={data.image.localFile.childImageSharp.gatsbyImageData}
        alt={data.image.alt}
      />
      <div className="content">
        <SiWikipedia className="wikipedia-icon" />
        <div className="quote">
          <p>{data.quote_text}</p>
          <Link to={data.wikipedia_url.url} target={data.wikipedia_url.target}>
            {data.wikipedia_url_label} <FiExternalLink />
          </Link>
        </div>
      </div>
    </WikipediaQuoteSection>
  );
};

export const query = graphql`
  fragment HomepageDataBodyCitationWikipedia on PrismicHomepageDataBodyCitationWikipedia {
    slice_type
    primary {
      section_id
      quote_text
      wikipedia_url_label
      wikipedia_url {
        url
        target
      }
      image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default WikipediaQuote;
