import * as React from 'react'
import styled from "styled-components"

// STYLE
const VideoPlayer = styled.div`
  position: relative;
  /* overflow: hidden; */
  width: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(80px);
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }

  &::after {
    display: block;
    content: "";
    padding-top: 56.25%;
  }
`

// MARK-UP
const Video = ({ thumbnailURL, videoURL, ...other }) => {
  const videoID = videoURL.split('/').slice(-1);

  return (
    <VideoPlayer>
      <div className="overlay">
        <img className="thumbnail" src={thumbnailURL} alt="Cliquer pour jouer la vidéo"></img>
      </div>
      <iframe width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${videoID}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </VideoPlayer>
  )
}

export default Video