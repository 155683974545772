import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { BgImage } from "gbimage-bridge"

// Components & Utils
import Link from '../utils/Link'
import Button from '../components/Button'
import Video from '../components/Video'

// STYLE
const HeroSection = styled(BgImage)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 600px;
  flex-wrap: wrap;
  gap: 40px;
  padding-top: 180px;
  padding-bottom: 130px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;

    .headline {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h1 {
        max-width: 425px;
      }

      p {
        max-width: 490px;
      }
    }

    .cta {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .reference {
      display: inline-flex;
      align-items: center;
      gap: 20px;
      border-radius: var(--border-radius);
      background-color: #ffffff00;
      transition: all .3s ease-in-out;
      padding: 10px;

      .divider {
        align-self: stretch;
        width: var(--border-width);
        background-color: var(--border-color);
      }

      .text {
        display: flex;
        flex-direction: column;

        .name {
          color: var(--primary-font-color);
          font-size: var(--medium-font-size);
        }

        .location {
          color: var(--secondary-font-color);
          font-size: var(--small-font-size);
        }
      }

      &:hover, &:active {
        transition: all .3s ease-in-out;
        background-color: #ffffff0d;
      }
    }

    .socials {
      display: flex;
      align-items: center;
      gap: 20px;

      .socials__item {
        display: flex;
        align-items: center;
        gap: 12px;
        border-radius: var(--border-radius);
        background-color: #ffffff00;
        transition: all .3s ease-in-out;
        padding: 10px;

        .icon {
          max-width: 20px;
        }

        span {
          color: var(--primary-font-color);
        }

        &:hover, &:active {
          transition: all .3s ease-in-out;
          background-color: #ffffff0d;
        }
      }
    }
  }

  .media {
    width: 100%;
    max-width: 600px;
  }

  &::before,
  &::after {
    /* filter: saturate(0); */
    mask-image: linear-gradient(var(--pages-background-color), transparent 95%);
  }
`

// MARK-UP
const Hero = ({ slice }) => {
  const data = slice.primary

  // Image de fond de la section
  const bgImage = getImage(data.background_image.localFile)
  const backgroundFluidImageStack = [
    bgImage
  ].reverse()

  // Logo de l'association
  const referenceIcon = getImage(data.emc_icon.localFile)

  //* Récupération des liens de réseaux sociaux depuis Prismic
  const configData = useStaticQuery(graphql`
    query SocialsLinksQuery {
      prismicSiteConfig {
        data {
          socials {
            icon {
              alt
              url
              gatsbyImageData
            }
            label
            url {
              url
              target
            }
            is_active
          }
        }
      }
    }
  `)

  const socials = configData.prismicSiteConfig.data.socials.map(item => ({
    icon: item.icon.gatsbyImageData,
    alt: item.icon.alt,
    label: item.label,
    url: item.url,
    isActive: item.is_active
  }))

  return (
    <HeroSection Tag="section" image={backgroundFluidImageStack} className={`container`} id={data.section_id}>

      <div className="content">
        <div className="headline">
          <h1>{ data.section_title.text }</h1>
          <p>{ data.section_paragraph }</p>
        </div>

        <div className="cta">
          { data.cta_btn_url.url && <Button type="primary" to={data.cta_btn_url.url} target={data.cta_btn_url.target}>{data.cta_btn_label}</Button> }
          { data.secondary_cta_btn_url.url && <Button type="secondary" to={data.secondary_cta_btn_url.url} target={data.secondary_cta_btn_url.target}>{data.secondary_cta_btn_label}</Button> }
        </div>

        <Link to={data.emc_url.url} target={data.emc_url.target} className="reference">
          { referenceIcon && <div className="logo"> <GatsbyImage image={referenceIcon} height={40} alt={data.emc_icon.alt} /> </div> }
          { referenceIcon && <div className="divider"></div> }
          <div className="text">
            <span className="name"> {data.emc_name} </span>
            { data.emc_location && <span className="location"> {data.emc_location} </span> }
          </div>
        </Link>

        {socials.length >= 1 &&
          <div className="socials">
            {socials.map(item => item.isActive && (
              <Link to={item.url.url} target={item.url.target} className="socials__item">
                <div className="icon"> <GatsbyImage image={item.icon} height={40} alt={item.alt} /> </div>
                {item.label && <span>{item.label}</span>}
              </Link>
            ))}
          </div>
        }
      </div>

      {data.video_url &&
        <div className="media">
          <Video thumbnailURL={data.video_url.thumbnail_url} videoURL={data.video_url.embed_url} />
        </div>
      }

    </HeroSection>
  )
}

// DONNEES
// Récupération des données depuis Prismic
export const query = graphql`
  fragment HomepageDataBodyHero on PrismicHomepageDataBodyHero {
    slice_type
    primary {
      section_paragraph
      section_id
      emc_icon {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      emc_name
      emc_location
      emc_url {
        url
        target
      }
      cta_btn_url {
        url
        target
      }
      cta_btn_label
      emc_url {
        url
        target
       }
      secondary_cta_btn_label
      secondary_cta_btn_url {
         url
         target
      }
      section_title {
        text
      }
      # video_url {
      #   embed_url
      #   thumbnail_url
      # }
      background_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default Hero