import * as React from "react"

// Slices
import Hero from '../slices/Hero'
import Activities from '../slices/Activities'
import WikipediaQuote from '../slices/WikipediaQuote'
import Process from '../slices/Process'
import ImageCarousel from '../slices/ImageCarousel'
import TeamPictures from '../slices/TeamPictures'
import OpeningHours from '../slices/OpeningHours'
import WhereToFindUs from '../slices/WhereToFindUs'
import Faq from '../slices/Faq'
import Contact from '../slices/Contact'

const SliceZone = ({ slices }) => {
  const sliceComponents = {
    hero: Hero,
    activites_du_club: Activities,
    citation_wikipedia: WikipediaQuote,
    processus_par_etapes: Process,
    carousel_de_photos: ImageCarousel,
    trombinoscope: TeamPictures,
    horaires_du_club: OpeningHours,
    section__ou_nous_trouver_: WhereToFindUs,
    foire_aux_questions: Faq,
    contact: Contact
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })
}

export default SliceZone