import * as React from 'react'
import { graphql } from 'gatsby'
import styled from "styled-components"

// Components & Utils
import Map from '../components/Map'

// STYLE
const WhereToFindUsSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;

  .section-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    h2 {
      max-width: 800px;
    }

    p {
      max-width: 560px;
    }
  }

  .map-container {
    width: 100%;
    aspect-ratio: 16/9;
  }
`

const WhereToFindUs = ({ slice }) => {
  const data = slice.primary
  const items = slice.items

  return (
    <WhereToFindUsSection className={`container`} id={data.section_id}>
      
      <div className="section-intro">
        <h2>{data.section_title.text}</h2>
        <p>{data.section_paragraph}</p>
      </div>

      <Map markers={items} />

    </WhereToFindUsSection>
  )
}

export const query = graphql`
  fragment HomepageDataBodySectionOuNousTrouver on PrismicHomepageDataBodySectionOuNousTrouver {
    slice_type
    primary {
      section_id
      section_title {
        text
      }
      section_paragraph
    }
    items {
      location_name
      location_adress
      location_coordinates {
        latitude
        longitude
      }
    }
  }
`

export default WhereToFindUs