import * as React from "react"
import { graphql } from "gatsby"

// Components & Utils
import Layout from "../layout/Layout"
import SliceZone from "../components/SliceZone"
import Seo from '../utils/Seo'

// markup
const IndexPage = ({ data }) => {
  if (!data) return null
  const doc = data.prismicHomepage.data

  return (
    <Layout>
      <Seo pageTitle={doc.page_title.text} />
      <SliceZone slices={doc.body} />
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    prismicHomepage {
      data {
        page_title {
          text
        }
        body {
          ...HomepageDataBodyHero
          ...HomepageDataBodyActivitesDuClub
          ...HomepageDataBodyCitationWikipedia
          ...HomepageDataBodyProcessusParEtapes
          ...HomepageDataBodyCarouselDePhotos
          ...HomepageDataBodyTrombinoscope
          ...HomepageDataBodyHorairesDuClub
          ...HomepageDataBodySectionOuNousTrouver
          ...HomepageDataBodyFoireAuxQuestions
          ...HomepageDataBodyContact
        }
      }
    }
  }
`

export default IndexPage
