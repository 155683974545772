import React, { useRef, useEffect } from 'react'
import styled from "styled-components"

// Importing Mapbox
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// STYLE
const MapContainer = styled.div`
  min-height: 400px;
  min-width: 400px;
  border-radius: calc(var(--border-radius) * 2);
  border: var(--border-width) solid var(--border-color);
  cursor: initial;

  .mapboxgl-popup-content {

    h3 {
      font-size: var(--medium-font-size);
      font-family: var(--text-font-family);
      color: var(--accent-color);
    }

    span {
      font-size: var(--small-font-size);
      font-family: var(--text-font-family);
      color: #000;
    }
  }

  .mapboxgl-marker {
    width: 14px;
    height: 14px;
    background-color: var(--accent-color);
    outline: 10px solid rgba(24, 57, 205, 0.2);
    border-radius: 100px;

    svg {
      width: 0;
      height: 0;
    }
  }
`
// MARK-UP
const Map = ({ markers, ...other }) => {

  // DEBUT DE : Configuration de Mapbox
  mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN;

  const mapContainer = useRef(null);
  const map = useRef(null);

  // Initialisation de la carte
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
    container: mapContainer.current,
    style: 'mapbox://styles/execko/cktmtjn5323w818o1idvjfaki'
    });
  }, []);

  // Configuration d'options après initialisation
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.scrollZoom.disable();
    map.current.addControl(new mapboxgl.FullscreenControl());
    map.current.addControl(new mapboxgl.NavigationControl());
  }, []);

  // Ajout des marqueurs
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    markers.map((data) => {
      const marker = new mapboxgl.Marker();
      marker.setLngLat([data.location_coordinates.longitude, data.location_coordinates.latitude]);
      marker.setPopup(new mapboxgl.Popup().setHTML(`<h3>${data.location_name}</h3><span>${data.location_adress}</span>`))
      marker.addTo(map.current);
      return null
    })
  }, [markers]);

  // Ajustement de la position de départ en fonction des marqueurs
  // useEffect(() => {
  //   if (!map.current) return; // wait for map to initialize
  //   const bounds = [];
  //   markers.map((position) => {
  //     bounds.push([position.location_coordinates.longitude, position.location_coordinates.latitude])
  //     return null
  //   })
  //   map.current.fitBounds(bounds, {padding: 40, linear: true});
  // }, [markers]);
  // FIN DE : Configuration de Mapbox

  return (
    <MapContainer ref={mapContainer} className="map-container" />
  )
}

export default Map