import * as React from 'react'
import { graphql } from 'gatsby'
import styled from "styled-components"

// Icons
import { FiCheck } from "react-icons/fi/";
import { FiExternalLink } from "react-icons/fi/";

// Components & Utils
import Link from '../utils/Link'
import Button from '../components/Button'

// STYLE
const ProcessSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    background-color: ${props => (props.bgColor || "#0F0D1B")};
    box-shadow: 0 2.5px 20px 1px rgba(0, 0, 0, 0.10);
    width: 100%;
    border-radius: calc(var(--border-radius) * 2);
    padding: 40px;
  }

  .section-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    h2 {
      max-width: 800px;
    }

    ul {
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2em;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8em;

        .icon-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          padding: 0.3em;
          position: relative;
          overflow: hidden;

          svg {
            color: var(--accent-color);
          }

          &::before {
            content: '';
            background-color: var(--accent-color);
            opacity: 0.1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        span {
          color: var(--primary-font-color);
        }
      }
    }
  }

  .steps {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3em;
    width: 100%;

    .list-item {
      display: flex;
      flex-direction: column;
      gap: 0.8em;
      max-width: 310px;
      /* margin: auto; */

      .badge {
        position: relative;
        border-radius: var(--border-radius);
        overflow: hidden;
        padding: 0.2em 0.6em;
        width: max-content;

        span {
          text-transform: uppercase;
          color: var(--accent-color);
          font-size: var(--small-font-size);
        }

        &::before {
          content: '';
          background-color: var(--accent-color);
          opacity: 0.1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        h3 {
          font-size: 1.2em;
          font-family: var(--text-font-family), sans-serif;
        }

        p {
          font-size: 1em;
        }

        a {
          display: flex;
          gap: 0.3em;
          font-size: var(--small-font-size);

          span {

          }
        }
      }
    }

    @media only screen and (max-width: 1200px) {
      justify-content: center;
    }
  }

  .contact-cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1.2em;
    flex-wrap: wrap;

    @media only screen and (max-width: 1200px) {
      justify-content: center;
    }
  }
`

const Process = ({ slice }) => {
  const data = slice.primary
  const items = slice.items

  return (
    <ProcessSection className={`container`} id={data.section_id} bgColor={data.background_color}>

      <div className="section-content">

        <div className="section-intro">
          <h2>{data.section_title.text}</h2>
          <ul className="key-points">
            {data.key_point_1 && <li><div className="icon-wrap"><FiCheck /></div><span>{data.key_point_1}</span></li>}
            {data.key_point_2 && <li><div className="icon-wrap"><FiCheck /></div><span>{data.key_point_2}</span></li>}
            {data.key_point_3 && <li><div className="icon-wrap"><FiCheck /></div><span>{data.key_point_3}</span></li>}
          </ul>
        </div>

        <div className="steps">
          {items.map((item, index) => (
            <div className="list-item" key={`step-${index}`}>
              <div className="badge"><span>{item.step_heading}</span></div>
              <div className="text">
                <h3>{item.step_title.text}</h3>
                <p>{item.step_paragraph}</p>
                {item.complimentary_url.url && 
                  <Link to={item.complimentary_url.url} target={item.complimentary_url.target}>
                    <span>{item.complimentary_url_label}</span>
                    {item.complimentary_url.target && <FiExternalLink />}
                  </Link>
                }
              </div>
            </div>
          ))}
        </div>

        <div className="contact-cta">
          <span>{data.cta_btn_paragraph}</span>
          { data.cta_btn_url.url && <Button type="secondary" to={data.cta_btn_url.url} target={data.cta_btn_url.target}>{data.cta_btn_label}</Button> }
        </div>

      </div>

    </ProcessSection>
  )
}

export const query = graphql`
  fragment HomepageDataBodyProcessusParEtapes on PrismicHomepageDataBodyProcessusParEtapes {
    slice_type,
    primary {
      section_id
      section_title {
        text
      }
      key_point_1
      key_point_2
      key_point_3
      cta_btn_paragraph
      cta_btn_label
      cta_btn_url {
        url
        target
      }
      background_color
    }
    items {
      step_title {
        text
      }
      step_heading
      step_paragraph
      complimentary_url_label
      complimentary_url {
        url
        target
      }
    }
  }
`

export default Process