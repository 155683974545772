import * as React from 'react'
import { graphql } from 'gatsby'
import styled from "styled-components"

// Components & Utils
import Carousel from '../components/Carousel'

// STYLE
const ImageCarouselSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .section-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    h2 {
      max-width: 800px;
    }

    p {
      max-width: 560px;
    }
  }

  .carousel {
    width: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 10%;
      z-index: 100;
      background: linear-gradient(90deg, var(--pages-background-color), transparent 100%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 10%;
      z-index: 100;
      background: linear-gradient(270deg, var(--pages-background-color), transparent 100%);
    }
  }
`

const ImageCarousel = ({ slice }) => {
  const data = slice.primary
  const items = slice.items

  // Préparation du carousel
  const imageStack = [];
  items.map(item => {
    imageStack.push({
      image: item.picture.localFile.childImageSharp.gatsbyImageData,
      alt: item.picture.alt,
      copyright: item.picture.copyright
    })
    return null
  })

  const carouselBreakpoints = {
    200: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1000: {
      slidesPerView: 3,
    },
  }

  const autoplayOptions = {
    delay: 1000,
    disableOnInteraction: false
  }

  return (
    <ImageCarouselSection className={`container`} id={data.section_id}>

      <div className="section-intro">
        <h2>{data.section_title.text}</h2>
        <p>{data.section_paragraph}</p>
      </div>

      <Carousel
        className="carousel"
        imageStack={imageStack}
        spaceBetween={30}
        slidesPerView={3}
        loop
        breakpoints={carouselBreakpoints}
        autoplay={autoplayOptions}
        allowTouchMove={false}
      />

    </ImageCarouselSection>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCarouselDePhotos on PrismicHomepageDataBodyCarouselDePhotos {
    slice_type
    primary {
      section_id
      section_title {
        text
      }
      section_paragraph
    }
    items {
      picture {
        alt
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default ImageCarousel