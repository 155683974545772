import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from "styled-components"

// Icons
import { FiChevronDown, FiExternalLink } from "react-icons/fi/";

// Components & Utils
import Link from '../utils/Link'

// STYLE
const FaqSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .section-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    h2 {
      max-width: 800px;
    }

    p {
      max-width: 560px;
    }
  }

  .questions {
    width: 100%;

    .question-container {

      &:not(:last-child) {
        border-bottom: var(--border-width) solid var(--border-color);
      }

      .question {
        padding: 20px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: var(--primary-font-color);
          font-size: 1.2em;
        }

        svg {
          transition: all .3s ease-in-out;
        }

        &:hover, &:active {
          transition: all .3s ease-in-out;
          filter: brightness(150%);
        }
      }

      .answer {
        max-height: 0;
        overflow: hidden;
        transition: all .3s ease-in-out;

        .answer-inner {
          padding: 0 20px 20px 20px;
          display: flex;
          flex-direction: column;
          gap: 0.6em;;

          p {
            color: var(--secondary-font-color);
            font-size: 1em;
          }

          a {
            font-size: var(--small-font-size);
            display: flex;
            gap: 0.4em;
          }
        }
      }

      &.active {
        .question {
          svg {
            transition: all .3s ease-in-out;
            color: var(--accent-color);
            transform: rotate(180deg);
          }
        }

        .answer {
          transition: all .3s ease-in-out;
          max-height: fit-content;
        }
      }
    }
  }
`

const Faq = ({ slice }) => {
  const data = slice.primary
  const items = slice.items

  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleClick = (index) => {
    index !== activeQuestion ? setActiveQuestion(index) : setActiveQuestion(null);
  }

  return (
    <FaqSection className={`container`} id={data.section_id}>

      <div className="section-intro">
        <h2>{data.section_title.text}</h2>
        <p>{data.section_paragraph}</p>
      </div>

      <div className="questions">
        {items.map((item, index) => (
          <div className={`question-container${activeQuestion === index+1 ? ` active` : ""}`} key={`question-container-${index + 1}`} aria-expanded={`${activeQuestion === index+1 ? true : false}`}>
            <div className="question" onClick={() => handleClick(index + 1)} onKeyDown={() => handleClick(index + 1)}>
              <span>{item.question}</span>
              <FiChevronDown />
            </div>
            <div className="answer">
              <div className="answer-inner">
                <p>{item.answer}</p>
                {item.complimentary_url.url && 
                  <Link to={item.complimentary_url.url} target={item.complimentary_url.target}>
                    <span>{item.complimentary_url_label}</span>
                    {item.complimentary_url.target && <FiExternalLink />}
                  </Link>
                }
              </div>
            </div>
          </div>
        ))}
      </div>

    </FaqSection>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFoireAuxQuestions on PrismicHomepageDataBodyFoireAuxQuestions {
    slice_type
    primary {
      section_id
      section_title {
        text
      }
      section_paragraph
    }
    items {
      question
      answer
      complimentary_url_label
      complimentary_url {
        target
        url
      }
    }
  }
`

export default Faq