import * as React from 'react'
import { graphql } from 'gatsby'

import styled from "styled-components"

// Icons
import { FiArrowRight } from "react-icons/fi/";

// STYLE
const ContactSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .section-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    h2 {
      max-width: 800px;
    }

    p {
      max-width: 560px;
    }
  }

  .contact-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    flex-wrap: wrap;
    width: 100%;

    .info-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5em;

      h3 {

      }

      p {

      }

      a {
        display: flex;
        align-items: center;
        gap: 0.4em;
        font-size: 2em;
        transition: all .3s ease-in-out;

        &:hover, &:active {
          gap: 0.6em;
          transition: all .3s ease-in-out;
        }

        @media only screen and (max-width: 600px) {
          font-size: 1.5em;
        }
      }
    }
  }
`

const Contact = ({ slice }) => {
  const data = slice.primary

  return (
    <ContactSection className={`container`} id={data.section_id}>
      
      <div className="section-intro">
        <h2>{data.section_title.text}</h2>
        <p>{data.section_paragraph}</p>
      </div>

      <div className="contact-info">
        {data.email_contact_adress && (
          <div className="info-item">
            {data.email_contact_title.text && <h3>{data.email_contact_title.text}</h3>}
            {data.email_contact_paragraph && <p>{data.email_contact_paragraph}</p>}
            <a href={`mailto:${data.email_contact_adress}`} target="_blank">
              <span>{data.email_contact_adress}</span>
              <FiArrowRight />
            </a>
          </div>
        )}

        {data.phone_contact_number && (
          <div className="info-item">
            {data.phone_contact_title.text && <h3>{data.phone_contact_title.text}</h3>}
            {data.phone_contact_paragraph && <p>{data.phone_contact_paragraph}</p>}
            <a href={`tel:${data.phone_contact_number}`} target="_blank">
              <span>{data.phone_contact_number}</span>
              <FiArrowRight />
            </a>
          </div>
        )}
      </div>

    </ContactSection>
  )
}

export const query = graphql`
  fragment HomepageDataBodyContact on PrismicHomepageDataBodyContact {
    slice_type
    primary {
      section_id
      section_title {
        text
      }
      section_paragraph
      email_contact_title {
        text
      }
      email_contact_paragraph
      email_contact_adress
      phone_contact_title {
        text
      }
      phone_contact_paragraph
      phone_contact_number
    }
  }
`

export default Contact