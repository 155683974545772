import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

// STYLE
const ActivitiesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .section-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    h2 {
      max-width: 800px;
    }

    p {
      max-width: 560px;
    }
  }

  .activities-list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;

    .list-item {
      position: relative;
      margin: auto;
      border-radius: var(--border-radius);
      overflow: hidden;
      flex-grow: 1;

      --item-size: 300px;

      min-width: var(--item-size);
      height: var(--item-size);

      .item-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, var(--accent-color) 0%, transparent 40%);
        }
      }

      h3 {
        position: absolute;
        bottom: 20px;
        left: 20px;
      }
    }
  }
`

const Activities = ({ slice }) => {
  const data = slice.primary
  const items = slice.items

  return (
    <ActivitiesSection className={`container`} id={data.section_id}>

      <div className="section-intro">
        <h2>{data.section_title.text}</h2>
        <p>{data.section_paragraph}</p>
      </div>

      <div className="activities-list">
        {items.map((item, index) => (
          <div className="list-item" key={`activity-${index}`}>
            <GatsbyImage className="item-image" image={item.activity_picture.localFile.childImageSharp.gatsbyImageData} alt={item.activity_picture.alt} />
            <h3>{item.activity_name.text}</h3>
          </div>
        ))}
      </div>

    </ActivitiesSection>
  )
}

export const query = graphql`
  fragment HomepageDataBodyActivitesDuClub on PrismicHomepageDataBodyActivitesDuClub {
    slice_type
    primary {
      section_id
      section_title {
        text
      }
      section_paragraph
    }
    items {
      activity_name {
        text
      }
      activity_picture {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default Activities