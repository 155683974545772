import * as React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Importing the carousel library
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

// STYLE
const CarouselContainer = styled(Swiper)`
  .swiper-slide {
    border-radius: calc(var(--border-radius) * 2);
  }
`
// MARK-UP
const Carousel = ({ imageStack, ...other }) => {

  return (
    <CarouselContainer modules={[Autoplay, Navigation, FreeMode]} {...other}>
      {imageStack.map((item, index) => (
          <SwiperSlide> <GatsbyImage className="swiper-slide" image={item.image} alt={item.alt} key={`slide-${index}`} /> </SwiperSlide>
      ))}
    </CarouselContainer>
  )
}

export default Carousel