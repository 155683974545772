import * as React from 'react'
import { graphql } from 'gatsby'
import styled from "styled-components"

// STYLE
const OpeningHoursSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .section-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;

    h2 {
      max-width: 800px;
    }

    p {
      max-width: 560px;
    }
  }

  .hours-cards {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 1em;
    width: 100%;
    flex-wrap: wrap;

    .card {
      border-radius: var(--border-radius);
      flex-grow: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 2em;
      border: calc(var(--border-width) * 2) solid var(--border-color);

      .location {
        display: flex;
        flex-direction: column;
        gap: 0.2em;

        .name {
          color: var(--primary-font-color);
          font-size: 1.5em;
        }

        .adress {
          color: var(--secondary-font-color);
          font-size: 0.9em;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 1em;

        p {
          line-height: 1.3em;
        }

        strong {
          color: var(--accent-color);
        }
      }
    }
  }
`

const OpeningHours = ({ slice }) => {
  const data = slice.primary
  const items = slice.items

  return (
    <OpeningHoursSection className={`container`} id={data.section_id}>
      
      <div className="section-intro">
        <h2>{data.section_title.text}</h2>
        <p>{data.section_paragraph}</p>
      </div>

      <div className="hours-cards">
        {items.map((item, index) => (
          <div className="card" key={`hours-card-${index}`}>
            <div className="location">
              <span className="name">{item.training_location}</span>
              <span className="adress">{item.training_location_adress}</span>
            </div>
            <div className="details" dangerouslySetInnerHTML={{__html: item.training_details.html}}></div>
          </div>
        ))}
      </div>

    </OpeningHoursSection>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHorairesDuClub on PrismicHomepageDataBodyHorairesDuClub {
    slice_type
    primary {
      section_id
      section_title {
        text
      }
      section_paragraph
    }
    items {
      training_location
      training_location_adress
      training_details {
        html
      }
    }
  }
`

export default OpeningHours